import React, { useState } from 'react'
import tw, { styled } from 'twin.macro'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import ReactPlayer from 'react-player'

import RaceTrack from './RaceTrack'

const getVideoId = (url) => {
	url = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
	return undefined !== url[2] ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0] // eslint-disable-line
}

const PlayIcon = ({ onClick, className }) => (
	<div onClick={onClick} className={className}>
		<svg width='83' height='100' viewBox='0 0 83 100' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M80.0055 44.8489C83.152 46.7681 83.2115 51.316 80.1162 53.3168L9.60764 98.8919C6.30542 101.026 1.94524 98.6898 1.89383 94.7581L0.726338 5.46693C0.674931 1.53526 4.97253 -0.914535 8.32943 1.13288L80.0055 44.8489Z'
				fill='white'
			/>
		</svg>
	</div>
)
const Container = styled.div`
	${tw`relative`}

	& video {
		height: 100% !important;
	}
`
const StyledImage = tw(GatsbyImage)`object-cover w-full h-auto aspect-h-9 aspect-w-16 max-h-[850px]`
const StyledStatic = tw.img`object-cover w-full h-auto max-h-[850px]`
const ThumbnailWrapper = tw.div`relative w-full h-auto`
const StyledPlayIcon = tw(PlayIcon)`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer`
const TextHeadline = tw.p`relative text-black max-w-[50%] 2xl:max-w-[664px] z-10 ml-offset-xs md:ml-offset-master`
const Placeholder = tw.h3`absolute font-futuraBold text-white max-w-[50%] 2xl:max-w-[664px]
text-[20px] leading-[20px]
xs:text-[32px] xs:leading-[32px]
lg:text-[50px] lg:leading-[50px]
xl:text-[70px] xl:leading-[70px]
2xl:text-[80px] 2xl:leading-[80px]  bottom-[11%] left-[6%] 3xl:bottom-[100px] 3xl:left-[100px]`

export const MediaContent = ({ type, className, svgImage, trackPoints, media, embed, placeholder, thumbnail, ...props }) => {
	const thumbnailSrc = getImage(thumbnail?.localFile)
	const [isPlaying, setIsPlaying] = useState(false)
	const [hasStarted, setHasStarted] = useState(false)
	const [playerVisible, setPlayerVisible] = useState(false) //used only if thumbnail is passed from CMS
	const playVideo = () => setIsPlaying(true)
	const pauseVideo = () => setIsPlaying(false)
	const startVideo = () => setHasStarted(true)
	const showVideo = () => {
		playVideo()
		setPlayerVisible(true)
	}
	//Thumbnail showing before video
	const Thumbnail = () => {
		return (
			<ThumbnailWrapper>
				<StyledImage image={thumbnailSrc} alt='' {...props} />
				<StyledPlayIcon onClick={showVideo} />
				<Placeholder>{placeholder}</Placeholder>
			</ThumbnailWrapper>
		)
	}
	const getEmbed = () => {
		if (!embed) {
			return null
		}
		const videoId = getVideoId(embed)
		if (thumbnail && !playerVisible) {
			return <Thumbnail />
		}
		return (
			<>
				<ReactPlayer
					className={`${className} aspect-w-16 aspect-h-9 max-h-[850px]`}
					url={embed}
					onStart={startVideo}
					controls={hasStarted}
					playing={isPlaying}
					onPause={pauseVideo}
					onPlay={playVideo}
					onEnded={pauseVideo}
					title={`EMF Racing ${videoId}`}
					src={`https://www.youtube-nocookie.com/embed/${videoId}`}
					config={{
						youtube: {
							playerVars: { controls: 2 },
						},
					}}
					{...props}
				/>
				<Placeholder>{!hasStarted && placeholder}</Placeholder>
			</>
		)
	}
	const getUploadedFile = () => {
		if (!media) {
			return null
		}
		const { mediaType, mediaItemUrl } = media
		if (mediaType === 'image') {
			if (media?.localFile?.childImageSharp) {
				const imageSrc = getImage(media?.localFile)
				return <StyledImage image={imageSrc} alt={media?.altText} {...props} />
			}
			return <StyledStatic src={`${media.mediaItemUrl}`} {...props} alt='' />
		}
		if (mediaType === 'file') {
			if (thumbnail && !playerVisible) {
				return <Thumbnail />
			}
			return (
				<>
					<ReactPlayer
						className={`${className} aspect-w-16 aspect-h-9 max-h-[850px]`}
						url={mediaItemUrl}
						preload='metadata'
						playsinline
						onStart={startVideo}
						controls={hasStarted}
						playing={isPlaying}
						onPause={pauseVideo}
						onPlay={playVideo}
						onEnded={pauseVideo}
						{...props}
					/>
					{!hasStarted && <StyledPlayIcon onClick={playVideo} />}
					<Placeholder>{!hasStarted && placeholder}</Placeholder>
				</>
			)
		}
	}

	return (
		<Container>
			{(media?.mediaType === 'image' || type === 'track') && <TextHeadline className='headline-h2'>{placeholder}</TextHeadline>}
			{type !== 'track' && !!media ? getUploadedFile() : getEmbed()}
			{type === 'track' && <RaceTrack svgImage={svgImage} trackPoints={trackPoints} />}
		</Container>
	)
}
