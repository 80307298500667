import React from 'react'
import tw, { styled } from 'twin.macro'
import { MediaContent } from '../elements/MediaContent'
import useDraggableScroll from 'use-draggable-scroll'

const Section = tw.section`relative pb-spacingS-master md:pb-spacingXl-xs max-w-screen-fhd h-auto mx-auto flex flex-col`
const Wrapper = tw.div`relative w-full lg:w-[91%] max-w-[1636px]`
const BottomDiv = styled.div`
	${tw`bg-greyStripe h-[9vw] min-h-[116px] max-h-[164px] z-50 flex flex-row flex-[25%] pr-5 overflow-y-hidden overflow-x-auto`}
	cursor: grab;

	::-webkit-scrollbar {
		height: 4px;
	}
	::-webkit-scrollbar-track {
		background: #3c3c3c;
	}
	::-webkit-scrollbar-thumb {
		background: #cccccc;
		border-radius: 10px;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #f5f5f5;
	}
`
const BottomItemHeadline = tw.h4`font-futuraBold text-white text-[24px] leading-[30px] 2xl:text-[40px] 2xl:leading-[30px] whitespace-nowrap`
const BottomItemText = tw.p`font-futuraBook text-white text-[12px] leading-[30px] 2xl:text-[20px] 2xl:leading-[30px] mt-4`
const Nbsp = () => '\u00A0'

const WpAcfMediainfoBlock = ({ data }) => {
	const { text, media, footer, thumbnail, embeded, type, svgImage, trackPoints } = data.modulesMediaInfo
	const ref = React.useRef(null)

	const { onMouseDown } = useDraggableScroll(ref, { direction: 'horizontal' })

	const BottomBar = () => {
		return (
			<BottomDiv ref={ref} onMouseDown={onMouseDown}>
				{footer.map((item, index) => (
					<div key={index} tw='flex flex-col h-full w-full justify-center pl-[6%] 3xl:pl-[102px] min-w-[220px] md:min-w-min'>
						<BottomItemHeadline>{item.headline}</BottomItemHeadline>
						<BottomItemText>{item.text ?? <Nbsp />}</BottomItemText>
					</div>
				))}
			</BottomDiv>
		)
	}
	return (
		<Section>
			<Wrapper>
				<MediaContent type={type} trackPoints={trackPoints} svgImage={svgImage} media={media && media} placeholder={text} thumbnail={thumbnail} embed={embeded} width='100%' height={'auto'} />
				<BottomBar />
			</Wrapper>
		</Section>
	)
}

export default WpAcfMediainfoBlock
