import React from 'react'
import tw, { styled } from 'twin.macro'
import { ReactSVG } from 'react-svg'
import Modal from '@elements/Modal'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'

const Wrapper = tw.div`object-cover w-full h-auto max-h-[850px]`
const StyledSVG = styled(ReactSVG)`
	${tw``}

	text {
		${tw`pointer-events-none`}
		&:hover {
			${tw`scale-110`}
		}
	}
	circle {
		transform-box: fill-box;
		${tw`pointer-events-none`}
		&.active {
			${tw`pointer-events-auto origin-center scale-[2.5] xs:scale-[2] sm:scale-100 translate-x-3 sm:translate-x-0 relative`}
			fill: #0054A1;

			&:hover {
				cursor: pointer;
				fill: #003899;
			}

			+ text {
				${tw`text-4xl sm:text-xl relative`}
			}
		}
		&.pulsating {
			stroke: #003899;
			stroke-width: 2px;
			stroke-opacity: 1;
			fill-opacity: 0;
			transform-origin: 50% 50%;
			animation-duration: 1.5s;
			animation-name: pulse;
			animation-iteration-count: infinite;

			@media (max-width: 640px) {
				animation-name: pulseBig;
				transform-origin: calc(50% - 8px) 50%;
			}
			@media (max-width: 480px) {
				transform-origin: calc(50% - 6px) 50%;
			}
		}
	}

	@keyframes pulse {
		from {
			stroke-width: 3px;
			stroke-opacity: 1;
			transform: scale(0.5);
		}
		to {
			stroke-width: 0;
			stroke-opacity: 0;
			transform: scale(2);
		}
	}
	@keyframes pulseBig {
		from {
			stroke-width: 3px;
			stroke-opacity: 1;
			transform: scale(1);
		}
		to {
			stroke-width: 3px;
			stroke-opacity: 0;
			transform: scale(3.5);
		}
	}
`
const ModalIframeWrap = tw.div`aspect-w-16 aspect-h-9 2xl:aspect-none 2xl:!relative w-full max-w-[800px] min-h-[33%] 2xl:h-[500px] 2xl:w-full max-h-[450px]`
const VideoFileWrap = tw.div`relative`

const Wrap = styled.div`
	${tw`h-full overflow-y-auto pr-4 max-h-[60vh] w-full`}
	::-webkit-scrollbar {
		width: 6px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
	}
	::-webkit-scrollbar-thumb {
		background: #cccccc;
		border-radius: 10px;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #f5f5f5;
	}
`
const ContentWrapper = tw.div``
const Title = tw.p`pb-offset-xs pt-2`
const Content = tw.div`pt-offset-xs`

const RaceTrack = ({ svgImage, trackPoints }) => {
	const [modalOpened, setModalOpened] = React.useState(false)
	const [modalContent, setModalContent] = React.useState('')

	const svgRef = React.useRef()

	const handleSvgClick = (target) => {
		if (target.nodeName !== 'circle') return
		const checkArray = trackPoints.filter((point) => point.trackPointId === target.nextElementSibling.textContent)[0]
		if (checkArray) {
			setModalContent(checkArray)
			setModalOpened(true)
		}
	}

	const fillActivePoints = () => {
		const svg = svgRef.current
		const points = svg.querySelectorAll(`text`)
		if (points.length > 0) {
			points.forEach((item) => {
				return trackPoints?.filter((content) => {
					if (item.textContent === content.trackPointId) {
						const cx = item.previousElementSibling.getAttribute('cx')
						const cy = item.previousElementSibling.getAttribute('cy')
						let circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle')
						circle.setAttribute('r', 18.2)
						circle.setAttribute('cx', cx)
						circle.setAttribute('cy', cy)
						circle.classList.add('pulsating')
						item.previousElementSibling.before(circle)
						return item.previousElementSibling.classList.add('active')
					}
					return ''
				})
			})
		}
	}

	const getVideoId = (url) => {
		url = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
		return undefined !== url[2] ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0] // eslint-disable-line
	}

	return (
		<>
			<Wrapper>
				<StyledSVG
					afterInjection={(error, svg) => {
						svgRef.current = svg
						trackPoints?.length > 0 && fillActivePoints()
					}}
					wrapper='div'
					ref={svgRef}
					src={svgImage?.localFile.publicURL}
					onClick={(event) => trackPoints?.length > 0 && handleSvgClick(event.target)}
				/>
			</Wrapper>
			<Modal modalContainerClassName='!h-auto sm:overflow-y-hidden max-w-screen-md' state={modalOpened} onClick={() => setModalOpened(false)}>
				<Wrap>
					<ContentWrapper>
						{modalContent?.title && <Title className='headline-50'>{parse(modalContent.title)}</Title>}
						{modalOpened && modalContent?.youtube && (
							<ModalIframeWrap>
								<iframe
									title={`EMF Racing ${getVideoId(modalContent.youtube)}`}
									src={`https://www.youtube-nocookie.com/embed/${getVideoId(modalContent.youtube)}?rel=0&autoplay=1`}
									frameBorder='0'
									className='!w-full !h-full '
									allow='autoplay; encrypted-media'
									allowFullScreen
								></iframe>
							</ModalIframeWrap>
						)}
						{modalOpened && modalContent?.image?.localFile && (
							<ModalIframeWrap>
								<GatsbyImage className='h-full w-full !absolute' image={getImage(modalContent.image.localFile)} alt={modalContent.image.altText || ''} />
							</ModalIframeWrap>
						)}
						{modalContent.videoFile?.localFile?.publicURL && modalOpened && (
							<VideoFileWrap>
								<video controls autoPlay muted playsInline src={modalContent.videoFile?.localFile?.publicURL} className='w-auto h-full max-h-[50vh]'></video>
							</VideoFileWrap>
						)}
					</ContentWrapper>
					{modalContent?.content && <Content>{parse(modalContent.content)}</Content>}
				</Wrap>
			</Modal>
		</>
	)
}

export default RaceTrack
